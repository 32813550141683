<template>
  <v-app id="inspire">
    <overall-header/>


    <v-main class=" lighten-3">
      <v-container>
        <v-row
            align="start"
            justify="space-around"
            style="padding: 5vh"
        >
          <h1>{{ project_title }}</h1>
        </v-row>

        <v-row
            justify="space-between"
        >
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td>Архивный</td>
                  <td>
                    <v-simple-checkbox
                        v-model="project_archive"
                        disabled
                    ></v-simple-checkbox>
                  </td>
                </tr>
                <tr>
                  <td>Статус</td>
                  <td>{{ project_status_title }}</td>
                </tr>
                <tr>
                  <td>Дедлайн</td>
                  <td>{{ project_deadline | formatDate }}</td>
                </tr>
                <tr>
                  <td>Дедлайн сбора оценок</td>
                  <td>{{ project_answer_deadline | formatDate }}</td>
                </tr>
                <tr>
                  <td>Файлы</td>
                  <td>
                    <div v-if="project_files.length">
                      <v-chip v-for="f in project_files" class="ma-1" v-bind:key="f.name">
                        <v-icon>mdi-download</v-icon>
                        <a :href="f.file" download="f.name" class="pa-2">
                          {{ f.name }}
                        </a>
                        <v-spacer></v-spacer>
                      </v-chip>
                    </div>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td>Описание</td>
                </tr>
                <tr>
                  <td v-html="project_description"></td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

        </v-row>
        <v-row justify="space-between" v-if="!project_archive">
          <v-col
              cols="12"
              md="12"
          >
            <v-card>
                  <v-card-title>
                Отправить предложение
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    label="Поиск по разделам"
                    flat
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                    append-icon="mdi-magnify"
                ></v-text-field>
              </v-card-title>
            

              <v-data-table
                  :headers="project_sections_headers"
                  :items="project_sections"
                  :search="search"
                  item-key="id"
                  sort-by="section"
                  group-by="section_parent"
                  class="elevation-1"
                  :items-per-page="1000"
                  dense
                  light
              >
                <template v-slot:item.cost="{ item }">
                  <v-text-field label="Оценка стоимости" dense
                                @input="valueChanged($event, 'cost', item)"
                                suffix="руб."
                                :rules="[rules.counter]"
                                clearable
                  ></v-text-field>
                </template>
                <template v-slot:item.comment="{ item }">
                  <v-text-field label="Комментарий" dense
                                @input="valueChanged($event,'comment', item)"
                                clearable
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-card>
            <v-spacer></v-spacer>

            <v-text-field
                v-model="user_email"
                label="Ваш e-mail"
                flat
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
                aria-required="true"
                required
                class="pa-4"
                :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-spacer></v-spacer>

              <v-row
                align="left"
              >
            <v-btn
                x-large
                color="success"
                @click="createAnswer"
            >
              Отправить оценку
            </v-btn>
              </v-row>
          </v-col>
        </v-row>
        <v-row justify="space-between" v-if="project_archive">
          <v-col
              cols="12"
              md="12"
          >
            <h1>Проект архивный. Оценки уже принимаются</h1>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import OverallHeader from "@/components/OverallHeader";

import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import {mapActions, mapGetters} from "vuex";
import axios from "@/helpers/axiosConfig";
import {validationMixin} from "vuelidate";
import {required} from 'vuelidate/lib/validators';
import Axios from "@/helpers/axiosConfig";

export default {
  name: "CreateProject",
  components: {OverallHeader},
  mixins: [validationMixin],

  validations: {
    user_email: {required},
    project_answers: {required},
  },

  data: () => ({
    project_deadline: new Date(Date.now()),
    project_deadline_menu: false,

    project_answer_deadline: new Date(Date.now()),
    project_answer_deadline_menu: false,
    project_description: "",
    project_status: '',
    project_status_title: '',
    project_title: "",
    project_archive: false,
    project_sections: [],
    project_files: [],
    project_summary_sections: [],
    project_answers: {},
    search: '',


    project_sections_headers: [
      // {
      //   text: '#',
      //   value: 'id',
      // },
      {
        text: 'Раздел',
        value: 'section_parent',
      },
      {
        text: 'Секция',
        value: 'section',
      },
      {
        text: 'Стоимость',
        value: 'cost',
      },
      {
        text: 'Комментарий',
        value: 'comment',
      }
    ],
    user_email: "",
    rules: {
      required: value => !!value || 'Обязательно.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Невалидный e-mail'
      },
      counter: value => {
        const pattern = /^[0-9]{1,}$/;
        return pattern.test(value) || 'Только цифры'
      }
    },
  }),

  computed: {
    ...mapGetters('statuses', {
      status_items: 'list'
    }),
  },

  methods: {
    ...mapActions('statuses', {
      fetchStatuses: 'fetchList'
    }),
    ...mapActions('tenders', {
      fetchProject: 'fetchSingle'
    }),

    loadDataForProject: function() {
      if (this.$route.params.slug) {
        let that = this;
        this.fetchProject({customUrlFnArgs: this.$route.params.slug}).then(function(response) {
          that.project_title = response.data.title;
          that.project_title = response.data.title;
          that.project_archive = response.data.archived;
          that.project_status = response.data.status;
          that.project_deadline = response.data.deadline;
          that.project_answer_deadline = response.data.answers_deadline;
          that.project_description = response.data.description;
          that.project_files = response.data.files;
        });
      }
    },

    fetchSections: function() {
      let that = this;
      axios.get('/api_v3/tender/' + this.$route.params.slug + '/sections/').then(function(response) {
        that.project_sections = response.data;
      });
    },

    valueChanged: function(value, key, item) {
      if (item.id in this.project_answers) {
        this.project_answers[item.id] = {...this.project_answers[item.id], [key]: value, id: item.id}
      } else {
        this.project_answers[item.id] = {
          [key]: value,
          id: item.id,
        }
      }
    },

    createAnswer: function() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        alert('Заполните email и хотя бы одну оценку')
      } else {
        axios.post(`/api_v3/tender/${this.$route.params.slug}/answer/`, {
          'answers': this.project_answers,
          email: this.user_email
        }).then(function() {
          alert('Спасибо!')
        });
      }
    },
    buildTitle: function() {
      if (this.status_items && this.project_status)
        this.project_status_title = this.status_items.find(x => x.id === this.project_status).title;
    },
    fetchCurrentUser: function() {
      let that = this;
      Axios.get('/api_v3/current-user/').then(function(response) {
        that.user_email = response.data.email;
      });
    },
  },

  watch: {
    status_items: "buildTitle",
    project_status: "buildTitle",
  },

  created() {
    this.fetchStatuses();
    this.loadDataForProject();
    this.fetchSections();
    this.fetchCurrentUser();
  },
}
</script>

<style scoped>

</style>